import { device } from "src/styles/breakpoints"
import { bluePetroleum } from "src/styles/colors"
import { ceramics } from "src/styles/newColors"
import styled from "styled-components"

export const SectionEspecialistasTrabalhando = styled.section`
  background: ${bluePetroleum};
  min-height: 847px;
  display: flex;
  position: relative;
  padding: 40px 0;

  @media ${device.tablet} {
    min-height: 493px;
    align-items: center;
  }
  @media ${device.desktopLG} {
    min-height: 593px;
  }
  @media ${device.desktopXL} {
    min-height: 745px;
  }

  h2 {
    span {
      color: ${ceramics};
    }
  }

  p {
    letter-spacing: 0;
  }

  button {

    &:hover {
      opacity: 0.9;
    }
    
    @media ${device.desktopLG} {
      max-width: 417px;
    }
    @media ${device.desktopXL} {
      max-width: 456px;
    }
  }
`
