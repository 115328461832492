import { device } from 'src/styles/breakpoints'
import { rounded } from 'src/styles/rounded'
import styled, { css, keyframes } from 'styled-components'
import { gray, grayscale, orange, white } from '../../../../styles/colors'

type ContainerProps = {
  isModal: boolean;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`

type SendButtonProps = {
  reverse?: boolean;
}

export const Container = styled.div<ContainerProps>`
  .range-slider > span.slider-container > span.bar {
    background: linear-gradient(90deg, rgb(255, 122, 0) 0%, rgb(222, 223, 228) 0%)
  }

  background-color: ${white};
  font-family: "Sora", Helvetica, sans-serif;

  .btn {
    text-transform: none;
  }

  button {
    max-width: 100% !important;
  }

  ${(props: ContainerProps) => props.isModal && (
    css`
    min-height: 100vh;
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;  
    right: 0;
    transition: 0.5s ease-in-out;
    animation: ${animatedModal} 0.5s ease-in-out forwards;

    > div{
      padding: 50px 30px 40px;
    }

    @media ${device.tablet} {
      max-width: 596px;
      padding: 40px 20px;
    }
    @media ${device.desktopXL} {
      max-width: 620px;
    }`
  )}

  .text-grayscale-500 {
    color: ${grayscale[500]};
  }

  .text-grayscale-400 {
    color: 6${grayscale[400]};
  }

  .form-hooks {
    display: flex;
    flex-wrap: wrap;

    input:not([type=range]) {
      width: 100%;
      font-size: 14px;
      line-height: 17px;
      padding: 0 10px;
      font-weight: 600;
      height: 48px;
      background: #F5F6FA;
      border-radius: 8px;
      border: none;
      
      &::placeholder {
        color: #B6B7BB;
      }

      &:focus {
        outline: none;
      }

      &.hasError {
        border: 1px solid red;
      }
    }
    .field--label-error {
      font-size: 12px;
      color: red;
    }

    .text-erro {
      color: #F56A50;
      font-family: "Inter", Helvetica, sans-serif;
    }

    button.bg-orange--linear {
      background: linear-gradient(7.83deg, ${orange.dark} 0%, ${orange.base} 100%);
      border-radius: ${rounded['1']};
      padding: 0 10px;
      height: 40px;
      border-radius: 8px;

      &:disabled {
        background: #e7e7e7;
      }
    }

    .formLabelCheck {
      position: relative;
      margin-left: 8px;
      margin-top: 20px;
      padding-left: 31px;

      &::before, &::after {
        display: block;
        position: absolute;
        content: '';
      }

      &::before {
        border: 2px solid #6A6C72;
        width: 20px;
        height: 20px;
        left: 0;
        top: 8px;
        border-radius: ${rounded['1']};
        
        @media ${device.tablet} {
          top: -1px
        }
      }
    }

    .formInputCheck {
      width: auto;
      margin-right: 10px;
      opacity: 0;
      height: 0;

      &:disabled ~ label {
        opacity: 0.6;
      }
    &:checked ~ label {
      &::before {
        border: 2px solid ${orange.extra};
        background-color: ${orange.extra};
      }
      &::after {
        left: 7px;
        top: 13px;
        width: 6px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);

        @media ${device.tablet} {
          top: 3px;
        }
      }
    }
  }
  .select {
    position: relative;
    display: flex;
    align-items: center;
    background: #ffffff;
    height: 40px;
    border-radius: 4px;
    padding: 10px; 

    select {
      width: 100%;
      background: none;
      outline: none;
      border: none;
      color: #B6B7BB;
      z-index: 1;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none; 
    }

    .icon {
      position: absolute;
      right: 30px;
      transition: rotate 0.5s ease-in-out;
    }

    select:active + .icon {
      transform: rotate(180deg);
    }
  }
  .radio-contato {
    display: flex;
    align-items: center;
    font-family: "Inter", Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #6A6C72;
    height: 48px;
    margin-bottom: 0;

    &:first-child {
      width: 103px;
    }

    & + label {
      margin-left: 19px;
    }

    input {
      display: none;
    }

    .radio-check {
      background: none;
      min-width: 18px;
      height: 18px;
      padding: 0;
      border: 2px solid #B6B7BB;
      border-radius: 50%;
      position: relative;
      margin-right: 10px;
    }

    input:checked + .radio-check {
      border-color: #FF7A00;

      &::after {
        content: '';
        width: 10.5px;
        height: 10.5px;
        background: #FF7A00;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }
}

.formLabelCheck {
  font-family: "Inter", Helvetica, sans-serif;

  &:before {
    top: 2px
  }

  &:after {
    top: 6px;
  }
}

.error {
  label,
  span,
  p {
    color: #F56A50;
  }
  
  input:not([type='range']) {
    background: #FEF0ED !important;
  }
}

.response {
  &-title { 
    color: #161616;
  }

  &-text {
    color: #6A6C72;
  }
}

.line {
  margin: 43px 0 19px;
}

.success {
  &-title {
    color: #6A6C72;
    font-family: "Sora", Helvetica, sans-serif;
    margin-bottom: 16px;
    display: block;
  }

  &-text {
    color: #161616;

    &--orange {
      color: #ff7a00;
    }
  }
}
label, input {
  font-family: "Inter", Helvetica, sans-serif !important;
}

.texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
  }  
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
`

export const Checkbox = styled.div`
  margin: 6px 0;
  input {
    display: block;
  }
  label{
    color:${grayscale[500]} !important;
  }

  .form-label-check {
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;

    &::before, &::after {
      display: block;
      position: absolute;
      content: '';
    }

    &::before {
      border: 2px solid ${gray['500']};
      border-radius: 3px;
      height: 20px;
      left: 0;
      top: 0;
      width: 20px;

      @media ${device.tablet} {
        top: 2px;
      }
    } 
    
    a {
      &:hover {
        color: ${gray['600']};
      }
    }
  }

  .form-input-check {
    height: 0 !important;
    opacity: 0;
    width: auto;

    &:disabled ~ label {
      opacity: 0.6;
    }
    &:checked ~ label {
    &::before {
      background: linear-gradient(7.83deg, ${orange.dark} 0%, ${orange.base} 100%);
      border: 1px solid ${orange.dark};
    }
    &::after {
      left: 7px;
      top: 3px;
      width: 6px;
      height: 10px;
      border: solid ${white};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);

      @media ${device.tablet} {
          top: 5px;
        }
      }
    }
  }
`

export const Button = styled.button`
  background: #FF7A00;
  border-radius: 8px;
  font-family: "Inter", Helvetica, sans-serif;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  border: none;
  width: 100%;
  height: 40px;
`

export const SendButton = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  color: ${(props: SendButtonProps) => props.reverse ? '#ff7a00' : ' #ffffff'};
  border: 1px solid #ff7a00;
  background: ${(props: SendButtonProps) => props.reverse ? '#ffffff' : ' #ff7a00'};
  font-size: 14px;
  line-height: 17px;

  &:disabled {
    border: 1px solid #cccccc;
    background: ${(props: SendButtonProps) => props.reverse ? '#ffffff' : ' #cccccc'};
    color: ${(props: SendButtonProps) => props.reverse ? '#cccccc' : ' #ffffff'};
  }

  &:hover {
    color: ${(props: SendButtonProps) => props.reverse ? '#ff7a00' : ' #ffffff'};
    border: 1px solid #ff7a00;
    background: ${(props: SendButtonProps) => props.reverse ? '#ffffff' : ' #ff7a00'};
    opacity: 0.9;

    &:disabled {
      border: 1px solid #cccccc;
      background: ${(props: SendButtonProps) => props.reverse ? '#ffffff' : ' #cccccc'};
      color: ${(props: SendButtonProps) => props.reverse ? '#cccccc' : ' #ffffff'};
      opacity: 1;
    }
  }
`
