import React, { Fragment, useEffect, useState } from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { ElementAction, IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import cardJson from '../../assets/data/_experiencia-certa-inter-win'
import { CardCarouselExperiences, RadiusBgColor, SectionExperiences } from './style'

type InterWinProps = {
  setIsModalOpen: Function;
  sendDatalayerEvent: (state: IDataLayerParams) => void;
}

const AexperienciaCertaInterWin = ({ setIsModalOpen, sendDatalayerEvent }: InterWinProps) => {
  const width = useWidth()
  const [ toNumber, setToNumber ] = useState<number>(0)
  const [ setDataLayer ] = useDataLayer()

  const dataLayer = (title: string, textButton: string, link?: string) => {
    return {
      section: 'dobra_06',
      section_name: `A experiência certa para você - ${title}`,
      element_action: 'click_button' as ElementAction,
      element_name: textButton,
      redirect_url: link ? link : null,
    }
  }

  useEffect(() => {
    const handleLoad = () => {
      if (width > 768) {
        setToNumber(1)
      } else if (width <= 768) {
        setToNumber(2)
      }
    }

    window.addEventListener('load', handleLoad)

    return () => {
      window.removeEventListener('load', handleLoad)
    }
  }, [ width ])

  return (
    <SectionExperiences className='py-5' id='a-experiencia-certa-para-voce'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <h2 className='font-citrina fs-28 lh-33 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 fw-600 '>
              A experiência certa para você
            </h2>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 mb-0'>
              Descubra os benefícios de cada perfil de relacionamento e encontre o que mais combina com você.
            </p>
          </div>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 20 }}
              md={{ items: 2, partialVisibilityGutter: 30 }}
              lg={{ items: 2, partialVisibilityGutter: 90 }}
              xl={{ items: 3 }}
              goToSlide={toNumber}
              customDotColor='#FF8700'
            >
              {
                cardJson.map((items: ExperiencesProps, index: number) => (
                  <Fragment key={index}>
                    <CardCarouselExperiences image={items.image} role='img' aria-label={items.alt}>
                      <RadiusBgColor bgColor={items.radiusBgColor} />
                      <div className='content-text px-4 mx-auto'>
                        <div>
                          <h3 className='font-citrina fs-24 lh-28 lh-md-28 fs-md-34 fs-lg-40 lh-lg-44 fw-600 mb-2'>
                            {items.title}
                          </h3>
                          <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 mb-4'>{items.description}</p>
                          {
                            items.listOfExperiences.map((list: ListOfExperiencesProps, index: number) => (
                              <div
                                key={index}
                                className='d-flex align-items-center justify-content-start mb-3'
                              >
                                <div className='mr-3 icon-adjustment'>
                                  <IconsSwitch
                                    icon={list.icon}
                                    color={width < WIDTH_MD ? '#3C3331' : '#fff'}
                                    lib='interco'
                                    width='24'
                                    height='24'
                                    customPath={list.customPath}
                                  />
                                </div>
                                <p className='fs-12 lh-15 fs-lg-14 lh-lg-17 mb-0'>
                                  {list.text}
                                </p>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div className='w-100 px-4 mt-4'>
                        {items.link !== ''
                          ? (
                            <a
                              href={items.link}
                              title={items.textButton}
                              className='btn btn--lg text-none'
                              onClick={() => {
                                setDataLayer(dataLayer(items.title, items.textButton, items.link))
                                sendDatalayerEvent(dataLayer(items.title, items.textButton, items.link))
                              }} rel='noreferrer'
                            >
                              {items.textButton}
                            </a>)
                          : (
                            <button
                              title={items.textButton}
                              className='btn btn--lg text-none mt-4'
                              onClick={() => {
                                setIsModalOpen && setIsModalOpen(true)
                                setDataLayer(dataLayer(items.title, items.textButton, items.link))
                                sendDatalayerEvent(dataLayer(items.title, items.textButton, items.link))
                              }}
                            >
                              {items.textButton}
                            </button>
                          )
                        }
                      </div>
                    </CardCarouselExperiences>
                  </Fragment>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </SectionExperiences>
  )
}

export default AexperienciaCertaInterWin
