import React from "react"
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from "src/hooks/useDataLayer/dataLayerBody"
import { IDataLayerParams } from "src/hooks/useDataLayer/typesUseDataLayer"
import useWidth from "src/hooks/window/useWidth"
import { WIDTH_MD } from "src/styles/breakpoints"
import { SectionComoSerCliente } from "./styles"

const ComoSerClienteInterWin = ({ setIsModalOpen, sendDatalayerEvent }: InterWinProps) => {
  const ImageMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-360-como-ser-inter/image.webp'
  const [ setDataLayer ] = useDataLayer()
  const width = useWidth()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_03',
    section_name: 'Como ser cliente Inter Win',
    element_action: 'click button',
    element_name: 'Saber mais',
  }

  return (
    <SectionComoSerCliente
      id='como-ser-cliente-inter-win'
      className='section_wrapper'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-5 col-xl-4 offset-md-7 px-xl-0'>
            {
              width < WIDTH_MD &&
                <div className='col-12 col-md-6 mb-4 d-flex justify-content-center'>
                  <ImageWebp
                    pathSrc={ImageMobile}
                    altDescription='Cartão Mastercard Platinum'
                    arrayNumbers={[ 312 ]}
                    arrayNumbersHeight={[ 312 ]}
                  />
                </div>
            }
            <h2 className='section_wrapper__content__title'><span>Como ser cliente</span> Inter Win</h2>
            <p className='section_wrapper__content__description'>
              Evolua seu perfil de relacionamento com uma carteira de investimentos a partir de R$1 milhão, seja com novos aportes
              ou aqueles que estão em outros bancos e corretoras.
            </p>
            <button
              className='section_wrapper__content__button'
              title='Saber mais'
              onClick={() => {
                setIsModalOpen(true)
                sendDatalayerEvent(dataLayer)
                setDataLayer(dataLayer)
              }}
            >
              Saber mais
            </button>
          </div>
        </div>
      </div>
    </SectionComoSerCliente>
  )
}

export default ComoSerClienteInterWin
