import { BaseModal as Modal } from '@interco/inter-ui/components/Modal/BaseModal'
import React, { useCallback, useState } from 'react'

import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { ElementAction } from 'src/hooks/useDataLayer/typesUseDataLayer'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'

import seuPatrimonioPotencializado from './../../assets/data/seu-patrimonio-potencializado.json'

import { Button, Card, ContainerWrapper, ContentCarousel, Section } from './style'

const ModalWidth = {
  md: 456,
  lg: 574,
  xl: 1000,
}

function setWidthByBreakpoint (width: number) {
  if (width >= widths.md && width < widths.lg) {
    return ModalWidth.md
  } else if (width >= widths.lg && width < widths.xl) {
    return ModalWidth.lg
  } else {
    return ModalWidth.xl
  }
}

const SeuPatrimonioPotencializado = ({ setIsModalOpen, sendDatalayerEvent }: InterWinProps) => {
  const width = useWidth(300)
  const [ visible, setVisible ] = useState<boolean>(false)
  const [ modalList, setModalList ] = useState<BeneficiosPropsWin[]>(seuPatrimonioPotencializado)
  const [ setDataLayer ] = useDataLayer()

  const dataLayer = (title?: string) => {
    return {
      section: 'dobra_02',
      section_name: `Seu patrimônio potencializado com propriedade ${title ? ('- ' + title) : ''}`,
      element_action: 'click_button' as ElementAction,
      element_name: `${title ? 'Quero ser Inter Win' : 'Eu quero'}`,
    }
  }

  const closeModal = useCallback(() => {
    setVisible(false)
  }, [ setVisible ])

  function ordenedFirstById (id: number) {
    const objectId = seuPatrimonioPotencializado.find((item: BeneficiosPropsWin) => item.id === id) || seuPatrimonioPotencializado[0]
    const newList = seuPatrimonioPotencializado.filter((item: BeneficiosPropsWin) => item.id !== id)
    newList.unshift(objectId)
    setModalList(newList)
    setVisible(true)
  }

  return (
    <>
      <Section className='py-5' id='seu-patrimonio-potencializado'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='font-citrina fs-28 lh-33 fs-md-28 lh-md-33 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 text-white fw-600 text-center'>
                Seu patrimônio potencializado com propriedade
              </h2>
              <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-white fw-400 text-center mb-0'>
                Compreendemos suas necessidades e criamos uma rota mais eficaz para a gestão do seu capital
              </p>
            </div>
            {
              width < widths.md && (
                <div className='col-12'>
                  <DefaultCarousel
                    sm={{ items: 1 }}
                    md={{ items: 2 }}
                    lg={{ items: 1 }}
                    xl={{ items: 1 }}
                    customColor='orange--extra'
                    customDotColor='#FF7A00'
                  >
                    {
                      seuPatrimonioPotencializado.map((item: BeneficiosPropsWin) => (
                        <ContentCarousel className='px-2' key={item.description}>
                          <ImageWebp
                            pathSrc={item.image}
                            arrayNumbers={[]}
                            arrayNumbersHeight={[]}
                            altDescription={item.altImage}
                          />
                          <h2 className='font-citrina fs-24 lh-28 text-white fw-700 mt-4' dangerouslySetInnerHTML={{ __html: item.title }} />
                          <p className='fs-14 lh-16 text-white fw-400 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                          <div className='d-flex justify-content-center'>
                            <Button
                              title='Eu quero'
                              onClick={() => {
                                setIsModalOpen(true)
                                setDataLayer(dataLayer())
                                sendDatalayerEvent(dataLayer)
                              }}
                            >
                              Eu quero
                            </Button>
                          </div>
                        </ContentCarousel>
                      ))
                    }
                  </DefaultCarousel>
                </div>
              )
            }
          </div>
          {
            width >= widths.md && (
              <>
                <div className='col-12'>
                  <DefaultCarousel
                    sm={{ items: 1 }}
                    md={{ items: 2, partialVisibilityGutter: 48 }}
                    lg={{ items: 3, partialVisibilityGutter: 16 }}
                    xl={{ items: 3, partialVisibilityGutter: 64 }}
                    xxl={{ items: 4, partialVisibilityGutter: 44 }}
                    customColor='orange--extra'
                    customDotColor='#FF7A00'
                  >
                    {
                      seuPatrimonioPotencializado.map((item: BeneficiosPropsWin) => (
                        <Card
                          key={item.description}
                          onClick={() => {
                            ordenedFirstById(item.id)
                            setDataLayer(dataLayer(item.title))
                            sendDatalayerEvent(dataLayer(item.title))
                          }}
                        >
                          <ImageWebp
                            pathSrc={item.imageMd}
                            arrayNumbers={[]}
                            arrayNumbersHeight={[]}
                            altDescription={item.altImage}
                          />
                          <div className='content'>
                            <h3
                              className='font-citrina fs-24 lh-28 text-white fw-600'
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            />
                          </div>
                        </Card>
                      ))
                    }
                  </DefaultCarousel>
                  <div className='d-flex justify-content-center'>
                    <Button
                      title='Eu quero'
                      onClick={() => {
                        setIsModalOpen(true)
                        setDataLayer(dataLayer())
                        sendDatalayerEvent(dataLayer)
                      }}
                    >
                      Eu quero
                    </Button>
                  </div>
                </div>
                <Modal
                  visible={visible}
                  onClose={closeModal}
                  onClickOutside={closeModal}
                  style={{ padding: 0 }}
                  bottomFade={false}
                  width={setWidthByBreakpoint(width)}
                >
                  <ContainerWrapper>
                    <DefaultCarousel
                      sm={{ items: 1 }}
                      md={{ items: 1 }}
                      lg={{ items: 1 }}
                      xl={{ items: 1 }}
                      xxl={{ items: 1 }}
                      customColor='blue-petroleum'
                      customDotColor='#2E3445'
                    >
                      {
                        modalList.map((item: BeneficiosPropsWin) => (
                          <div className='px-2' key={item.description}>
                            <div className='row d-flex align-items-center justify-content-between'>
                              <div className='col-12 col-xl-6 order-xl-latest'>
                                <ImageWebp
                                  pathSrc={item.imageDesktopOpenModal}
                                  arrayNumbers={[]}
                                  arrayNumbersHeight={[]}
                                  altDescription={item.altImage}
                                />
                              </div>
                              <div className='col-12 col-xl-5 order-xl-first mt-3'>
                                <h4 className='fs-16 lh-19 fw-600 my-3'>BENEFÍCIOS DE SER CLIENTE INTER WIN</h4>
                                <h2
                                  className='font-citrina fs-24 lh-28 fs-md-28 lh-md-33 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 fw-600 mt-md-4 mb-xl-4'
                                  dangerouslySetInnerHTML={{ __html: item.title }}
                                />
                                <p
                                  className='fs-14 lh-16 fs-md-16 lh-md-19 fs-xl-18 lh-xl-21 mb-0 mt-4'
                                  dangerouslySetInnerHTML={{ __html: item.description }}
                                />
                                <div className='d-flex justify-content-center'>
                                  <Button
                                    title='Quero ser Inter Win'
                                    onClick={() => {
                                      setIsModalOpen(true)
                                      setDataLayer(dataLayer(item.title))
                                      sendDatalayerEvent(dataLayer(item.title))
                                    }}
                                  >
                                    Quero ser Inter Win
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </DefaultCarousel>
                  </ContainerWrapper>
                </Modal>
              </>
           )}
        </div>
      </Section>
    </>
  )
}

export default SeuPatrimonioPotencializado
