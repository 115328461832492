import React, { useState } from 'react'
import Layout from './../../../../layouts/BaseLayout'

import { IDataLayerParams } from '../../../../hooks/useDataLayer/typesUseDataLayer'
import useDomReady from '../../../../hooks/window/useDomReady'

import { Modal } from 'src/components/Modal'
import WinForm from 'src/components/UI/Forms/WinForm/index'

import S1_HeroInterWin from './sections/S1_hero-inter-win/_index'
import S2_SeuPatrimonioPotencializado from './sections/S2_seu-patrimonio-potencializado/_index'
import S3_ComoSerClienteInterWin from './sections/S3_como-ser-cliente-inter-win/_index'
import S4_EspecialistasTrabalhando from './sections/S4_especialistas-trabalhando/_index'
import S5_CartaoMastercardWin from './sections/S5_cartao-mastercard-win/_index'
import S6_AexperienciaCertaInterWin from './sections/S6_a-experiencia-certa/_index'
import S7_SaberMaisSobreOinterWin from './sections/S7_saber-mais-sobre-o-inter-win/_index'
import S8_FaqInterWin from './sections/S8_faq-inter-win/_index'

import pageContext from './pageContext.json'

const IndexPageComponent: React.FC = (): React.ReactElement => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const domReady = useDomReady()

  const [ dataLayer, sendDatalayerEvent ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Inter Win - Gestão inteligente do seu patrimônio',
    element_action: 'click button',
    element_name: 'Quero ser Inter Win',
  })

  const modalForm = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <WinForm
        closeModal={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        dataLayer={dataLayer}
        customOrigin='LP_WIN'
      />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {modalForm}
      <S1_HeroInterWin setIsModalOpen={setIsModalOpen} sendDatalayerEvent={sendDatalayerEvent} />
      <S2_SeuPatrimonioPotencializado setIsModalOpen={setIsModalOpen} sendDatalayerEvent={sendDatalayerEvent} />
      <S3_ComoSerClienteInterWin setIsModalOpen={setIsModalOpen} sendDatalayerEvent={sendDatalayerEvent} />
      <S4_EspecialistasTrabalhando setIsModalOpen={setIsModalOpen} sendDatalayerEvent={sendDatalayerEvent} />
      <S5_CartaoMastercardWin setIsModalOpen={setIsModalOpen} sendDatalayerEvent={sendDatalayerEvent} />
      <S6_AexperienciaCertaInterWin setIsModalOpen={setIsModalOpen} sendDatalayerEvent={sendDatalayerEvent} />
      <S7_SaberMaisSobreOinterWin />
      <S8_FaqInterWin />
    </Layout>
  )
}

export default IndexPageComponent
