import React from 'react'

import FAQ from 'src/components/Faq'

import pageContext from '../../pageContext.json'

const FaqComponent = () => {
  return (
    <section className='py-5' id='faq-inter-win'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='font-citrina text-grayscale--500 fs-28 lh-32 fs-md-40 lh-md-44 mb-4 pb-2'>
              Perguntas frequentes
            </h3>
            <FAQ
              id='faq-inter-win'
              className='summary-content px-0 text-white'
              columns={{ lg: 1, xl: 1 }}
              answerData={pageContext.structuredData.faq}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaqComponent
