import { device } from "src/styles/breakpoints"
import styled from "styled-components"

const BgCartaoMasterCardWinMobile = "https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-background-360-win/image.webp"
const BgCartaoMasterCardWinTabletDesktop = "https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-background-win/image.webp"

export const SectionCartaoMasterCard = styled.section`
  background: #fff url(${BgCartaoMasterCardWinMobile}) no-repeat top center;
  background-size: cover;
  min-height: 938px;
  display: flex;
  position: relative;
  padding: 40px 0;

  @media ${device.tablet} {
    background: url(${BgCartaoMasterCardWinTabletDesktop}) no-repeat top center;
    background-size: cover;
    min-height: 689px;
    align-items: center;
  }
  @media ${device.desktopXL} {
    min-height: 820px;
  }
`
