import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import { bluePetroleum, ceramics } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${bluePetroleum};
  overflow: hidden;

  @media (min-width: ${breakpoints.md}) {
    min-height: 716px;
  }

  .react-multi-carousel-list {
    overflow: visible;
  }

  p {
    min-height: 51px;
  }
`

export const ContentCarousel = styled.div`
  padding-left: 8px;
  padding-right: 8px;

  p {
    min-height: 68px;
  }
`

export const Card = styled.div`
  position: relative;
  margin-bottom: 42px;
  transition: transform 0.3s ease-in;
  cursor: pointer;
  
  @media (min-width: ${breakpoints.md}) {
    height: 380px;
    margin-right: 24px;
    border-radius: 16px;
    margin-bottom: 10px;

    &:hover {
      transform: scale(1.1);
    }
  }
  
  img {
    border-radius: 16px;
  }

  .content {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(0deg, rgba(46, 52, 69, 0.2), rgba(46, 52, 69, 0));
    padding: 24px;
    display: flex;
    align-items: flex-end;
    border-radius: 16px;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 8px;
  color: ${white};
  background: ${orange[500]};
  font-size: 16px;
  font-weight: 700;
  line-height: 14px;
  margin-top: 32px;

  &:hover {
    opacity: 0.9;
    color: ${grayscale[200]};
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 24px;
    width: 456px ;
  }
`

export const ContainerWrapper = styled.div`
  background-color: ${ceramics};
  padding: 104px 44px 40px 44px;
  display: block ;
  min-height: 76px;

  h2, h3, h4 {
    color: ${bluePetroleum};
  }

  @media (min-width: ${breakpoints.md}){
    padding: 40px 44px;
  }

  img {
     width: 100%;
     object-fit: cover;
     border-radius: 8px;

    @media (min-width: ${breakpoints.md}){
      height: 268px;
    }
    @media (min-width: ${breakpoints.xl}){
      width: 376px;
      height: 376px;
      object-fit: unset;
    }
  }

  p {
    color: ${bluePetroleum};
    min-height: 76px;
  }
`
