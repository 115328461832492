import styled from "styled-components"

import { device } from "src/styles/breakpoints"
import { brand, white } from "src/styles/colors"
import { bluePetroleum } from "src/styles/newColors"

const BgComoSerClienteLeft = 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-ser-cliente-win/image.webp'
const BgComoSerClienteRightMobile = "https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-360-background/image.webp"
const BgComoSerClienteRightDesktop = "https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-1440-background/image.webp"
const BgComoSerClienteRightDesktopXXL = "https://central-imagens.bancointer.com.br/images-without-small-versions/bg-como-ser-cliente-desktop-xxll/image.webp"

export const SectionComoSerCliente = styled.section`
  min-height: 648px;
  background-color: ${bluePetroleum};
  background: url(${BgComoSerClienteRightMobile}) no-repeat top center;
  background-size: cover;
  align-items: end;
  display: flex;
  position: relative;
  padding: 40px 0;

  @media ${device.tablet} {
    min-height: 502px;
    background-color: ${bluePetroleum};
    background-image: url(${BgComoSerClienteLeft}), url(${BgComoSerClienteRightDesktop});
    background-position: center left -60px, center right -197px;
    background-size: 393px, 610px;
    background-repeat: no-repeat, no-repeat;
    align-items: center;
  }
  @media ${device.desktopLG} {
    min-height: 681px;
    background-position: center left -50px, center right -200px;
    background-size: 477px, 740px;
    background-repeat: no-repeat, no-repeat;
  }
  @media ${device.desktopXL} {
    min-height: 742px;
    background-position: center left 0, center right 0;
    background-size: 520px, 805px;
    background-repeat: no-repeat, no-repeat;
  }
  @media ${device.desktopXXXL} {
    background-image: url(${BgComoSerClienteLeft}), url(${BgComoSerClienteRightDesktopXXL});
    background-position: center left 0, center right -120px;
    background-size: 520px, 996px;
    background-repeat: no-repeat, no-repeat;
  }
  @media ${device.desktopXXLL} {
    background-position: center left 0, center right 0;
  }

  .container {

    .row {
      @media ${device.desktopXXLL} {
        justify-content: center;
      }
    }
  }

  .section_wrapper {

    &__content {
    
      &__title {
        font-family: "Citrina", Helvetica, sans-serif;
        color: ${bluePetroleum};
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 0;
        margin-bottom: 16px;

        @media ${device.tablet} {
          font-size: 40px;
          line-height: 44px;
        }
        @media ${device.desktopLG} {
          font-size: 48px;
          line-height: 50px;
        }

        span {
          display: block;
        }
      }
      
      &__description {
        font-family: "Citrina", Helvetica, sans-serif;
        color: ${bluePetroleum};
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 32px;
        letter-spacing: 0;

        @media ${device.tablet} {
          font-size: 16px;
          line-height: 20px;
        }
        @media ${device.desktopLG} {
          font-size: 18px;
          line-height: 22px;
        }
      }

      &__button {
        color: ${white};
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        background: ${brand.primary};
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 100%;
        max-width: 100%;

        @media ${device.desktopLG} {
          max-width: 386px;
        }

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
`
