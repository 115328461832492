import React, { useEffect, useState } from 'react'

import IconsSwitch from 'src/components/IconsSwitch/_index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD } from 'src/styles/breakpoints'
import { SectionEspecialistasTrabalhando } from './styles'

const EspecialistasTrabalhandoWin = ({ setIsModalOpen, sendDatalayerEvent }: InterWinProps) => {
  const [ setDataLayer ] = useDataLayer()
  const [ isImage, setIsImage ] = useState('')
  const width = useWidth(360)

  const ImageTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra-768/image.webp'
  const ImageDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-1440-1024-360/image.webp'

  useEffect(() => {
    width === WIDTH_MD ? setIsImage(ImageTablet) : setIsImage(ImageDesktop)
  }, [ width ])

  const dataLayer: IDataLayerParams = {
    section: 'dobra_04',
    section_name: 'Especialistas trabalhando para o que é mais importante: você.',
    element_action: 'click button',
    element_name: 'Quero ser Inter Win',
  }

  const listOfBenefits = [
    {
      icon: "ic_rotate_money",
      customPath: "finance/",
      description: "Profissionais qualificados e dedicados a análise e gestão de ativos financeiros;",
    },
    {
      icon: "ic_currency_exchange",
      customPath: "finance/",
      description: "Conferências recorrentes sobre suas metas de curto e longo prazo;",
    },
    {
      icon: "ic_house_trending_up",
      customPath: "finance/",
      description: "Elaboração de um diagnóstico patrimonial feito exclusivamente para você;",
    },
    {
      icon: "ic_target",
      customPath: "home-objects/",
      description: "Análise completa de mercado para elaborar estratégias alinhadas aos seus objetivos. ",
    },
  ]

  return (
    <SectionEspecialistasTrabalhando id='especialistas-trabalhando'>
      <div className='container'>
        <div className='row align-items-md-center justify-content-md-between'>
          <div className='col-12 col-md-6 d-flex justify-content-center justify-content-md-end order-md-last mb-4 mb-md-0'>
            <ImageWebp
              pathSrc={isImage}
              altDescription='Cartão Mastercard Platinum'
              arrayNumbers={[ 334.33, 336, 456, 552, 552 ]}
              className='mb-4 mb-md-0'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='font-citrina text-white fs-28 lh-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 fw-600 mb-4'>
              Especialistas trabalhando para o que é mais importante: <span>você</span>.
            </h2>
            {listOfBenefits.map((benefit: ListOfBenefitsProps, index: number) => (
              <div key={index} className='d-flex align-items-center justify-content-start my-2 float-left'>
                <div className='mr-3'>
                  <IconsSwitch
                    icon={benefit.icon}
                    color='#fff'
                    lib='interco'
                    width='24'
                    height='24'
                    customPath={benefit.customPath}
                  />
                </div>
                <div>
                  <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 text-white mb-0'>
                    {benefit.description}
                  </p>
                </div>
              </div>
            ))}
            <button
              title='Quero ser Inter Win'
              className='btn btn--lg bg-orange--extra text-white text-none mt-3 mt-md-4'
              onClick={() => {
                setIsModalOpen(true)
                setDataLayer(dataLayer)
                sendDatalayerEvent(dataLayer)
              }}
            >
              Quero ser Inter Win
            </button>
          </div>
        </div>
      </div>
    </SectionEspecialistasTrabalhando>
  )
}

export default EspecialistasTrabalhandoWin
