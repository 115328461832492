import React, { useEffect, useState } from 'react'

import IconsSwitch from 'src/components/IconsSwitch/_index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD } from 'src/styles/breakpoints'
import { SectionCartaoMasterCard } from './styles'

const CartaoMastercardWin = ({ setIsModalOpen, sendDatalayerEvent }: InterWinProps) => {
  const [ setDataLayer ] = useDataLayer()
  const [ isImage, setIsImage ] = useState('')
  const width = useWidth(360)

  const ImageMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-360-win-c/image.webp'
  const ImageDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-1440-win/image.webp'

  useEffect(() => {
    width < WIDTH_MD ? setIsImage(ImageMobile) : setIsImage(ImageDesktop)
  }, [ width ])

  const dataLayer: IDataLayerParams = {
    section: 'dobra_05',
    section_name: 'Cartão Mastercard Win',
    element_action: 'click button',
    element_name: 'Saber mais',
  }

  const listOfBenefits = [
    {
      icon: "ic_credit_card",
      customPath: "finance/",
      description: "Mastercard Surpreenda",
    },
    {
      icon: "ic_plane_departure",
      customPath: "travel-mobility/",
      description: "Mastercard Airport Concierge",
    },
    {
      icon: "ic_luggage",
      customPath: "travel-mobility/",
      description: "Mastercard Travel Rewards",
    },
    {
      icon: "ic_globe",
      customPath: "home-objects/",
      description: "Assistência Global de Emergência",
    },
    {
      icon: "ic_sparkle",
      customPath: "action-navigation/",
      description: "Isenção de rolha e mais",
    },
  ]

  return (
    <SectionCartaoMasterCard id='cartao-mastercard-win'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-xl-7 d-flex justify-content-center justify-content-md-start'>
            <ImageWebp
              pathSrc={isImage}
              altDescription='Homem grisalho, concentrado observando seu notebook.'
              arrayNumbers={[ 334.33, 340, 450, 580, 580 ]}
              className='mb-4 mb-md-0'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 px-xl-2'>
            <h2 className='font-citrina text-white fs-28 lh-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 fw-600 mb-3'>
              Cartão Mastercard Win
            </h2>
            <h3 className='font-citrina text-white fs-16 lh-20 fs-md-18 lh-md-22 fs-lg-20 lh-lg-24 fs-xl-24 lh-xl-28 mb-4 fw-600 pr-xl-5'>
              É internacional, sem anuidade e com os melhores benefícios do mercado.
            </h3>
            <p className='text-white fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-18 lh-xl-22 mb-4'>
              Com o Inter Loop, você acumula 1 ponto a cada R$2,00 em compras no cartão de crédito para trocar por vantagens da sua preferência.
              E o melhor: usando com recorrência, seus pontos não expiram.
            </p>
            <h4 className='fs-16 lh-20 fs-md-20 lh-md-25 text-white'>
              Benefícios Mastercard
            </h4>
            {listOfBenefits.map((benefit: ListOfBenefitsProps, index: number) => (
              <div key={index} className='d-flex align-items-center justify-content-start mb-3'>
                <div className='mr-3'>
                  <IconsSwitch
                    icon={benefit.icon}
                    color='#fff'
                    lib='interco'
                    width='24'
                    height='24'
                    customPath={benefit.customPath}
                  />
                </div>
                <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-white mb-0'>
                  {benefit.description}
                </p>
              </div>
            ))}
            <button
              title='Saber mais'
              className='btn btn--lg bg-white text-orange--extra text-none fw-600 mt-3 mt-md-4'
              onClick={() => {
                setIsModalOpen(true)
                setDataLayer(dataLayer)
                sendDatalayerEvent(dataLayer)
              }}
            >
              Saber mais
            </button>
          </div>
        </div>
      </div>
    </SectionCartaoMasterCard>
  )
}

export default CartaoMastercardWin
