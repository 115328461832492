import React from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import * as S from './style'

const SaberMaisSobreOinterWin = () => {
  const width = useWidth()

  return (
    <S.SectionSaberMaisWin id='quer-saber-mais-sobre-o-inter-win'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <S.TitleH2>Quer saber mais sobre o Inter Win?</S.TitleH2>
            <S.Description>Entre em contato com o nosso time para tirar dúvidas nos canais oficiais de relacionamento.</S.Description>
          </div>
          <div className='col-12 col-md-6'>
            <S.CardSaberMaisWin>
              <div className='d-flex align-items-center justify-content-start'>
                <div className='mr-3 mb-1'>
                  <IconsSwitch
                    icon='ic_star'
                    color='#2E3445'
                    lib='interco'
                    width={width < WIDTH_MD ? '16' : '24'}
                    height={width < WIDTH_MD ? '16' : '24'}
                    customPath='action-navigation/'
                  />
                </div>
                <div>
                  <S.TitleH3>
                    Quero ser Inter Win
                  </S.TitleH3>
                </div>
              </div>
              <S.DescriptionSpan>
                Entre em contato pelo telefone <strong>(31) 3003-4070</strong>
              </S.DescriptionSpan>
              <S.DescriptionSpan>Envie uma mensagem no <strong><a href='https://inter-co.onelink.me/Qyu7/lhr5pivo' title='Envie uma mensagem no chat' target='_blank' rel='noreferrer'>chat</a></strong></S.DescriptionSpan>
            </S.CardSaberMaisWin>

            <S.CardSaberMaisWin className='last-card-small'>
              <div className='d-flex align-items-center justify-content-start'>
                <div className='mr-3 mb-1'>
                  <IconsSwitch
                    icon='ic_user'
                    color='#2E3445'
                    lib='interco'
                    width={width < WIDTH_MD ? '16' : '24'}
                    height={width < WIDTH_MD ? '16' : '24'}
                    customPath='human/'
                  />
                </div>
                <div>
                  <S.TitleH3>
                    Já sou cliente Inter Win
                  </S.TitleH3>
                </div>
              </div>
              <S.DescriptionSpan>
                Entre em contato pelo Whatsapp <strong>(31) 3003-8477</strong>
              </S.DescriptionSpan>
              <S.DescriptionSpan>Envie uma mensagem para o seu advisor pela <strong><a href='https://inter-co.onelink.me/Qyu7/7mxh4j3k' title='Envie uma mensagem para Área Exclusiva Inter Win' target='_blank' rel='noreferrer'>Área Exclusiva</a></strong> do Super App</S.DescriptionSpan>
            </S.CardSaberMaisWin>
          </div>
          <div className='col-12 col-md-6'>
            <S.CardSaberMaisWin className='last-card'>
              <div className='d-flex align-items-center justify-content-start'>
                <div className='mr-3 mb-1'>
                  <IconsSwitch
                    icon='ic_location'
                    color='#2E3445'
                    lib='interco'
                    width={width < WIDTH_MD ? '16' : '24'}
                    height={width < WIDTH_MD ? '16' : '24'}
                    customPath='travel-mobility/'
                  />
                </div>
                <div>
                  <S.TitleH3>
                    Endereços comerciais
                  </S.TitleH3>
                </div>
              </div>
              <S.ItemsAddress>
                <S.DescriptionStrong>Belo Horizonte</S.DescriptionStrong>
                <S.DescriptionCard>
                  Av. Barbacena, 1219 – Santo Agostinho, <br /> Belo Horizonte | MG <br /> CEP 30190-131
                </S.DescriptionCard>
              </S.ItemsAddress>
              <S.ItemsAddress>
                <S.DescriptionStrong>São Paulo</S.DescriptionStrong>
                <S.DescriptionCard className='last-item'>
                  Av. Juscelino Kubitscheck, 1400 – 8º <br /> andar, São Paulo | SP <br /> CEP 04543-000
                </S.DescriptionCard>
              </S.ItemsAddress>
              <S.DescriptionStrong>
                E-mail
              </S.DescriptionStrong>
              <S.DescriptionSpan className='email'>contato.win@bancointer.com.br</S.DescriptionSpan>
            </S.CardSaberMaisWin>
          </div>
        </div>
      </div>
    </S.SectionSaberMaisWin>
  )
}

export default SaberMaisSobreOinterWin
