import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { SectionInterWin } from './styles'

const InterWinGestaoInteligente = ({ setIsModalOpen, sendDatalayerEvent }: InterWinProps) => {
  const [ setDataLayer ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Inter Win - Gestão inteligente do seu patrimônio',
    element_action: 'click button',
    element_name: 'Quero ser Inter Win',
  }

  return (
    <SectionInterWin
      id='inter-win-gestao-inteligente'
      role='img'
      className='section_wrapper'
      aria-label='Mulher grisalha de roupas formais com um sorriso no rosto'
    >
      <div className='container section_wrapper__content'>
        <div className='row'>
          <div className='col-12 col-md-7 col-xl-5'>
            <h1 className='section_wrapper__content__title'>
              Inter Win
            </h1>
            <p className='section_wrapper__content__description'>
              <span>Gestão inteligente </span>do seu patrimônio
            </p>
            <p className='section_wrapper__content__sub_description'>
              Para você focar no que é inesquecível
            </p>
            <button
              className='section_wrapper__content__button'
              title='Quero ser Inter Win'
              onClick={() => {
                setIsModalOpen(true)
                sendDatalayerEvent(dataLayer)
                setDataLayer(dataLayer)
              }}
            >
              Quero ser Inter Win
            </button>
          </div>
        </div>
      </div>
    </SectionInterWin>
  )
}

export default InterWinGestaoInteligente
