import { device } from "src/styles/breakpoints"
import { brand, white } from "src/styles/colors"
import { bluePetroleum, graphiteOne } from "src/styles/newColors"
import styled from "styled-components"

const HeroInterOneDesktop = "https://central-imagens.bancointer.com.br/images-without-small-versions/persona-win-1440/image.webp"
const HeroInterOneMobile = "https://central-imagens.bancointer.com.br/images-without-small-versions/winn-hero-mobile/image.webp"

export const SectionInterWin = styled.section`
  min-height: 640px;
  background: ${bluePetroleum} url(${HeroInterOneMobile}) no-repeat top center;
  background-size: cover;
  align-items: end;
  display: flex;
  position: relative;
  padding: 40px 0;

  @media ${device.tablet} {
    min-height: 516px;
    background: ${graphiteOne} url(${HeroInterOneDesktop}) no-repeat top center;
    background-size: cover;
    align-items: center;
  }
  @media ${device.desktopLG} {
    min-height: 576px;
  }
  @media ${device.desktopXL} {
    min-height: 100vh;
  }

  .section_wrapper {

    &__content {
    
      &__title {
        font-family: "Citrina", Helvetica, sans-serif;
        color: ${white};
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: 0;
        margin-bottom: 16px;

        @media ${device.tablet} {
          font-size: 56px;
          line-height: 60px;
        }
        @media ${device.desktopXL} {
          font-size: 104px;
          line-height: 108px;
        }
      }
      
      &__description {
        font-family: "Citrina", Helvetica, sans-serif;
        color: ${white};
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 16px;
        letter-spacing: 0;

        @media ${device.desktopLG} {
          margin-bottom: 24px;
        }
        @media ${device.desktopXL} {
          font-size: 48px;
          line-height: 52px;
        }

        span {

          @media ${device.desktopXL} {
            display: block;
          }
        }
      }

      &__sub_description {
        color: ${white};
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 24px;

        @media ${device.tablet} {
          margin-bottom: 40px;
        }
        @media ${device.desktopLG} {
          font-size: 18px;
          line-height: 22px;
        }
        @media ${device.desktopXL} {
          font-size: 20px;
          line-height: 24px;
        }
      }

      &__button {
        color: ${white};
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        background: ${brand.primary};
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 100%;
        max-width: 100%;

        @media ${device.tablet} {
          max-width: 319px;
        }
        @media ${device.desktopLG} {
          max-width: 386px;
        }

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
`
