import { breakpoints } from 'src/styles/breakpoints'
import { bluePetroleum, brand } from 'src/styles/colors'
import { ceramics } from 'src/styles/newColors'
import styled from 'styled-components'

export const SectionSaberMaisWin = styled.section`
  background: ${ceramics};
  min-height: 889px;
  color: ${bluePetroleum};
  padding: 40px 0;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    min-height: 621px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 568px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 644px;
  }
`

export const TitleH2 = styled.h2`
  margin-bottom: 16px;
  font-size: 28px;
  line-height: 32px;
  font-family: "Citrina", Helvetica, sans-serif;

  @media (min-width: ${breakpoints.md}) {
    text-align: center;
  }
  @media (min-width: ${breakpoints.xl}) {
    font-size: 40px;
    line-height: 46px;
  }
`

export const TitleH3 = styled.h3`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;

  @media (min-width: ${breakpoints.md}) {
    font-size: 16px;
    line-height: 17px;
  }
  @media (min-width: ${breakpoints.xl}) {
    font-size: 18px;
    line-height: 22px;
  }
`

export const Description = styled.p`
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 17px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 16px;
    line-height: 17px;
    text-align: center;
  }

  @media (min-width: ${breakpoints.xl}) {
    font-size: 18px;
    line-height: 22px;
  }
`

export const CardSaberMaisWin = styled.div`
  position: relative;
  margin-bottom: 24px;
  border: 1px solid ${bluePetroleum};
  padding: 24px;
  border-radius: 16px;
  color: ${bluePetroleum};
  min-height: 142px;

  @media (min-width: ${breakpoints.md}) {
    min-height: 170px;
    display: flex;
    flex-direction: column;
  }

  &.last-card {
    margin-bottom: 0;

    @media (min-width: ${breakpoints.md}) {
      min-height: 376px;
      display: flex;
      flex-direction: column;
    }
    @media (min-width: ${breakpoints.lg}) {
      min-height: 365px;
    }
  }
  &.last-card-small {
    
    @media (min-width: ${breakpoints.md}) {
      margin-bottom: 0;
    }
  }
`

export const DescriptionSpan = styled.span`
  margin-top: 16px;
  font-size: 14px;
  line-height: 17px;
  display: block;

  strong {
    color: ${brand.primary};
  }

  &.email {
    margin-top: 0;
  }
`

export const ItemsAddress = styled.div`
  display: block;
  margin: 16px 0;
`

export const DescriptionCard = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 17px;
  display: block;
  color: ${bluePetroleum};
  letter-spacing: 0;

  &.last-item {
    margin-bottom: 32px;
  }
`

export const DescriptionStrong = styled.strong`
  font-size: 14px;
  line-height: 17px;
  display: block;
  color: ${bluePetroleum};

  a {
    cursor: pointer;
  }
`
